import React, { useContext } from "react";
import { Button } from "src/components/Button";
import { ACTIVE_PRICING_DURATIONS, SubscriptionContext } from "src/services/SubscriptionService";
import { DEFAULT_TWO_SPORTS, STATE_MAP } from "src/constants";
import { ProductOptions } from "../index";
import styles from "./styles.scss";
import { currentSport } from "src/services/ApolloService";
import { useFreeTrialClaimed, useVouchers } from "src/services/UserService";
import AnalyticsService from "src/services/AnalyticsService";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";

const PERIODS = [
  {
    period: ACTIVE_PRICING_DURATIONS[0], // month
    tier: ACTIVE_PRICING_DURATIONS[0].tiers[0], // web_betql_premium_month
    sportList: DEFAULT_TWO_SPORTS,
    goToCheckout: false,
    title: "Monthly Pass",
    price: "$19.99/mo",
  },
  {
    period: ACTIVE_PRICING_DURATIONS[1], // year
    tier: ACTIVE_PRICING_DURATIONS[1].tiers[1], // web_betql_premium_year
    sportList: DEFAULT_TWO_SPORTS,
    goToCheckout: false,
    title: "Annual Pass",
    price: "$59.99/yr",
  },
];

interface PeriodViewProps {
  style?: any;
  className?: string;
  onSelectPeriod(options: ProductOptions): void;
  toggleModal(): void;
}

export default function PeriodView(props: PeriodViewProps) {
  const ctx = useContext(SubscriptionContext);
  const vouchers = useVouchers();
  const redeemed = vouchers.indexOf("BetMGM") > -1;
  const trialClaimed = useFreeTrialClaimed();

  const activateBetMGM = () => {
    props.onSelectPeriod({
      showOfferSteps: true,
      showCheckout: false,
      showDowngrade: false,
      showUpgrade: false,
    });
    AnalyticsService.track("Deposit on BetMGM Button", {
      page: window.location.href,
      sport: currentSport()?.name,
      source: "Pricing Modal",
    });
  };

  const periodItems = PERIODS.map(({ tier, period, sportList, goToCheckout, title, price }, index) => {
    const productOptions = {
      selectedPeriod: period,
      selectedTier: tier,
      sportList,
      showCheckout: goToCheckout,
      showDowngrade: false,
      showUpgrade: false,
      showOfferSteps: false,
    };
    return (
      <div key={index} className="period">
        {!trialClaimed && <div className="period-tag">3 DAY FREE TRIAL</div>}
        <div className="period-title">{title}</div>
        {!trialClaimed && <div className="period-subtitle">Full Access for 3 Days, Then</div>}
        <div className="period-price">{price}</div>
        <Button className="period-button" onClick={() => props.onSelectPeriod(productOptions)}>
          {!trialClaimed ? (
            <>
              Start Trial Now
              <Icon path={mdiArrowRight} size="20px" color="#fff" />
            </>
          ) : (
            `Buy ${period.text} Pass`
          )}
        </Button>
      </div>
    );
  });

  return (
    <div className={`period-view ${props.className || ""}`} style={props.style}>
      <div className="period-view-title">Get Full Access Now!</div>
      <div className="period-view-list">{periodItems}</div>
      {STATE_MAP[ctx.userLocale]?.legal ? (
        <div className="free-sub-offers">
          <div className="offer-text">Deposit $10 and place a bet on BetMGM to get 12 free months of BetQL!</div>
          <div className="offer-buttons">
            <div
              onClick={() => !redeemed && activateBetMGM()}
              className={`betmgm-button ${redeemed ? "redeemed" : ""}`}
            >
              <img src="/logos/BetMGM-icon.jpg" />
              <span className="button-text">Deposit on BetMGM</span>
              {redeemed && <span className="redeemed-text">You already deposited on BetMGM</span>}
            </div>
          </div>
        </div>
      ) : null}
      <style jsx>{styles}</style>
    </div>
  );
}
