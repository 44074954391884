import nookies from "nookies";
import moment from "moment-timezone";
import { IS_CLIENT } from "src/constants";

const COOKIE_NAME = "cxpt";

// Get cxd from cookie (cxd is cellxpert's tracking token)

export const getCxd = () => {
  let cookie: any = nookies.get()[COOKIE_NAME];
  return cookie ? JSON.parse(cookie).cxd : undefined;
};

// Capture affId and cxd from query string

export const captureAffiliate = () => {
  if (IS_CLIENT) return; // client only

  const query = new URL(window.location.href).searchParams;
  const affId = query.get("affId"); // affiliate id
  const cxd = query.get("cxd");

  if (!affId || !cxd) return; // detect a proper affiliate link

  // get existing cookie
  let existingCookie: any = nookies.get()[COOKIE_NAME];
  if (existingCookie) existingCookie = JSON.parse(existingCookie);

  if (existingCookie && existingCookie.affId === affId) return; // already tracking

  // set new cookie
  const newCookie = JSON.stringify({ affId, cxd });
  const expires = moment().add(60, "d").toDate();
  nookies.set(null, COOKIE_NAME, newCookie, { path: "/", expires });
};
