import moment, { Moment } from "moment-timezone";
import { TierItem } from "../typings/pricing";
import { ScheduleItem } from "./ScheduleService";
import { getProductDuration } from "./SubscriptionService";
import { getFreeTrialClaimed } from "./UserService";
import { useEffect, useState } from "react";
import { DEFAULT_TIMEZONE } from "src/constants";

const BEFORE_DATE_RANGE: number = 3;
const AFTER_DATE_RANGE: number = 0;

export const getSportChangeRange = (tier: TierItem) => {
  const today = moment();
  const endDate = moment();
  const trialEndDate = endDate.add(3, "days");

  if (getFreeTrialClaimed()) {
    return `Renews ${getProductDuration(tier.key)}ly at $${tier.price}`; // prettier-ignore
  }
  return `Free from ${today.format("MMMM D YYYY")} to \
${trialEndDate.format("MMMM D YYYY")}, \
then $${tier.price}/${tier.durationDisplayAbv}`;
};

export const getStartDateValue = (startDate: string) => {
  return moment.utc(startDate).valueOf();
};

export const useStartDateTime = (startDate: string, showToday?: boolean) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);
  if (!startDate) {
    return "--";
  }
  const startTime = moment.tz(startDate, timezone);
  const today = moment.tz(timezone);
  if (startTime.isSame(today, "day")) {
    return showToday ? `Today, ${startTime.format("h:mm A")}` : startTime.format("h:mm A");
  }
  let tomorrow = moment.tz(timezone).add(1, "d");
  if (startTime.isSame(tomorrow, "day")) {
    return `Tomorrow, ${startTime.format("h:mm A")}`;
  }
  let yesterday = moment.tz(timezone).subtract(1, "d");
  if (startTime.isSame(yesterday, "day")) {
    return `Yesterday, ${startTime.format("h:mm A")}`;
  }
  return startTime.format("ddd M/D, h:mm A");
};

export const useStartDate = (startDate: Moment) => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);
  const today = moment.tz(timezone).startOf("day");
  if (startDate.isSame(today, "day")) {
    return "Today";
  }
  const tomorrow = moment.tz(timezone).add(1, "d").startOf("day");
  if (startDate.isSame(tomorrow, "day")) {
    return "Tomorrow";
  }
  const yesterday = moment.tz(timezone).subtract(1, "d").startOf("day");
  if (startDate.isSame(yesterday, "day")) {
    return "Yesterday";
  }
  return startDate.format("ddd M/D");
};

export const useLocalDate = (date: string, format: string = "MMM D, YYYY, h:mma z"): string => {
  const [timezone, setTimezone] = useState(DEFAULT_TIMEZONE);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);
  return moment.tz(date, timezone).format(format);
};

export const getScheduleIndex = (schedule: ScheduleItem[]): number => {
  if (!schedule || schedule.length < 1) {
    return 0;
  }
  const firstStartDate = moment(schedule[0].startDate);
  const lastEndDate = moment(schedule[schedule.length - 1].endDate);
  if (firstStartDate.isAfter()) {
    // Season hasn't started yet, find the first schedule entry that
    // we can use if the we need to skip preseason
    const index = schedule.findIndex((period) => !period.skip);
    // Default to 0 if we don't find a valid index
    return index === -1 ? 0 : index;
  } else if (lastEndDate.isBefore()) {
    // Season is over, use the last index
    return schedule.length - 1;
  } else {
    // In season, find the schedule entry that we dont' want to skip and is
    // either the current week or a future week
    const now = moment();
    const index = schedule.findIndex((period) => {
      const startDate = moment(period.startDate);
      const endDate = moment(period.endDate);
      return !period.skip && (now.isBetween(startDate, endDate) || startDate.isAfter());
    });
    // Default to 0 if we don't find a valid index
    return index === -1 ? 0 : index;
  }
};

export const getScheduleStartDate = (schedule: ScheduleItem[]): Moment | undefined => {
  if (!schedule || schedule.length < 1) {
    return;
  }
  const firstStartDate = moment(schedule[0].startDate);
  const lastEndDate = moment(schedule[schedule.length - 1].endDate);
  if (firstStartDate.isAfter()) {
    if (!firstStartDate) {
      return;
    } else {
      const selectedPeriod = schedule.find((period) => !period.skip);
      if (selectedPeriod) {
        return moment(selectedPeriod.startDate);
      }
    }
  } else if (lastEndDate.isBefore()) {
    const lastStartDate = moment(schedule[schedule.length - 1].startDate);
    return lastStartDate;
  } else {
    const week = schedule.find((period) => {
      const periodStartDate = moment(period.startDate);
      const periodEndDate = moment(period.endDate);
      return !period.skip && (moment().isBetween(periodStartDate, periodEndDate) || periodStartDate.isAfter());
    });
    if (week) {
      return moment(week.startDate);
    }
  }
};

export const getScheduleEndDate = (schedule: ScheduleItem[]): Moment | undefined => {
  if (!schedule || schedule.length < 1) {
    return;
  }
  const firstStartDate = moment(schedule[0].startDate);
  const lastEndDate = moment(schedule[schedule.length - 1].endDate);
  if (firstStartDate.isAfter()) {
    if (!schedule[0].skip) {
      return moment(schedule[0].endDate);
    } else {
      const selectedPeriod = schedule.find((period) => !period.skip);
      if (selectedPeriod) {
        return moment(selectedPeriod.endDate);
      }
    }
  } else if (lastEndDate.isBefore()) {
    return lastEndDate;
  } else {
    const week = schedule.find((period) => {
      const periodStartDate = moment(period.startDate);
      const periodEndDate = moment(period.endDate);
      return !period.skip && (moment().isBetween(periodStartDate, periodEndDate) || periodStartDate.isAfter());
    });
    if (week) {
      return moment(week.endDate);
    }
  }
};

/**
 * Get the default before date range of the given league for the home page. This
 * method temporarily resolves two issues: 1) NFL/NCAAF has a weekly schedule vs daily on
 * other sports and 2) We want to show upcoming games when not in season. E.G: NFL games in June
 *
 * These are temporary fixes until we can get gql that can query for events with limit variable.
 *
 * @param league The name of the league we're searching for
 */
export const getDefaultBeforeDateRange = (league: string, schedule: ScheduleItem[]) => {
  if (league) {
    const lowerLeague = league.toLowerCase();
    switch (lowerLeague) {
      case "nfl":
      // TODO add this for the superbowl
      // return moment.tz("2022-02-14", DEFAULT_TIMEZONE).tz("UTC");
      // eslint-disable-next-line no-fallthrough
      case "ncaaf":
      case "xfl":
      case "deb":
      case "epl":
      case "esp":
      case "itsa":
      case "ucl":
      case "mls":
        const endDate = getScheduleEndDate(schedule);
        if (endDate) {
          return endDate;
        }
    }
  }

  return moment().tz(DEFAULT_TIMEZONE).add(BEFORE_DATE_RANGE, "day").endOf("day").utc();
};

/**
 * Get the default after date range of the given league for the home page. This
 * method temporarily resolves two issues: 1) NFL/NCAAF has a weekly schedule vs daily on
 * other sports and 2) We want to show upcoming games when not in season. E.G: NFL games in June
 *
 * These are temporary fixes until we can get gql that can query for events with limit variable.
 * @param league The name of the league we're searching for
 */
export const getDefaultAfterDateRange = (league: string, schedule: ScheduleItem[]) => {
  if (league) {
    const lowerLeague = league.toLowerCase();
    switch (lowerLeague) {
      case "nfl":
      // TODO add this for the superbowl
      // return moment.tz("2022-02-06", DEFAULT_TIMEZONE).tz("UTC");
      // eslint-disable-next-line no-fallthrough
      case "ncaaf":
      case "xfl":
      case "deb":
      case "epl":
      case "esp":
      case "itsa":
      case "ucl":
      case "mls":
        const startDate = getScheduleStartDate(schedule);
        if (startDate) {
          return startDate;
        }
    }
  }

  return moment().tz(DEFAULT_TIMEZONE).subtract(AFTER_DATE_RANGE, "day").startOf("day").utc();
};

/**
 * Get the default selected date of the given league for the games page. This
 * method temporarily resolves two issues: 1) NFL/NCAAF has a weekly schedule vs daily on
 * other sports and 2) We want to show upcoming games when not in season. E.G: NFL games in June
 *
 * These are temporary fixes until we can get gql that can query for events with limit variable.
 * @param sportKey The name of the league we're searching for
 */
export const getDefaultSelectedDate = (sportKey: string | string[] | undefined) => {
  if (sportKey === "mlb") {
    // TODO add this for MLB start
    const mlbSpringEnd = moment("2023-03-29");
    const mlbRegStart = moment("2023-03-30");
    if (mlbSpringEnd.isBefore() && mlbRegStart.isAfter()) {
      return mlbRegStart;
    }
  }
  if (sportKey === "ncaab") {
    // TODO update this for NCAA Tournament in march
    // This likely doesn't really matter as much anymore
    const ncaabSelectionDate = moment("2024-03-17T23:00:00Z");
    const ncaabTournyDate = moment("2024-03-19");
    if (ncaabSelectionDate.isBefore() && ncaabTournyDate.isAfter()) {
      return ncaabTournyDate;
    }
  }
  // if (sportKey === "nfl") {
  //   // TODO update this for the Superbowl.
  //   const nflDate = moment.tz("2022-02-13", DEFAULT_TIMEZONE);
  //   if (nflDate.isAfter()) {
  //     return nflDate;
  //   }
  // }
  return moment().tz(DEFAULT_TIMEZONE);
};

export const sortByDate = (eventA: any, eventB: any) => {
  const startMomentA = moment(eventA.startDate);
  const startMomentB = moment(eventB.startDate);
  if (startMomentA.isBefore(startMomentB)) {
    return -1;
  }
  if (startMomentA.isAfter(startMomentB)) {
    return 1;
  }
  return 0;
};
