import React, { useContext, useRef, useState } from "react";
import { Modal } from "reactstrap";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiClose } from "@mdi/js";
import { useReactiveVarListener } from "src/services/UtilService";
import PeriodView from "./PeriodView";
import PricingView from "./PricingView";
import AuthView from "./AuthView";
import CheckoutView from "./CheckoutView";
import DowngradeView from "./DowngradeView";
import MobileSubView from "./MobileSubView";
import UpgradeView from "./UpgradeView";
import ThankYouView from "./ThankYouView";
import OfferStepsView from "./OfferStepsView";
import {
  mobileSubscriptionLookup,
  webSubscriptionLookup,
  ACTIVE_PRICING_DURATIONS,
  ACTIVE_MOBILE_PRODUCTS,
  useUserSubscriptions,
  getTierSportCount,
  getProductTier,
  SubscriptionContext,
} from "src/services/SubscriptionService";
import AnalyticsService from "src/services/AnalyticsService";
import { DEFAULT_TWO_SPORTS } from "src/constants";
import { TierItem, DurationItem } from "src/typings/pricing";
import styles from "./styles.scss";
import { useEffect } from "react";
import { pricingModalDeepLink, sportCountMismatch } from "src/services/ApolloService";
import { useRouter } from "next/router";

const tiers: any = {
  prem: 0,
  pro: 1,
  vip: 2,
  sharp: 3,
};

export interface ProductOptions {
  sportList?: string[];
  selectedTier?: TierItem;
  selectedPeriod?: DurationItem;
  upsellTier?: TierItem;
  showDowngrade?: boolean;
  showUpgrade?: boolean;
  showCheckout?: boolean;
  showOfferSteps?: boolean;
}

export interface PricingModalProps {
  visible: boolean;
  toggleModal(): void;
}

const initState = {
  sportList: DEFAULT_TWO_SPORTS,
  selectedTier: ACTIVE_MOBILE_PRODUCTS[1][0], // web_betql_pro_month
  selectedPeriod: ACTIVE_PRICING_DURATIONS[0], // monthly
  upsellTier: ACTIVE_MOBILE_PRODUCTS[3][1], // web_betql_sharp_annual
  showDowngrade: false,
  showUpgrade: false,
  showCheckout: false,
  showOfferSteps: false,
  disabled: false,
  tabIndex: 0,
};

const PricingModal = () => {
  const ctx = useContext(SubscriptionContext);
  const visible = ctx.pricingModalVisible;
  const userSubscriptions = useUserSubscriptions({ skip: !visible });
  const [selectedTier, setSelectedTier] = useState(initState.selectedTier); // prettier-ignore
  const [sportList, setSportList] = useState(initState.sportList);
  const [selectedPeriod, setSelectedPeriod] = useState(initState.selectedPeriod); // prettier-ignore
  const [upsellTier, setUpsellTier] = useState(initState.upsellTier);
  const [showCheckout, setShowCheckout] = useState(initState.showCheckout);
  const [showOfferSteps, setShowOfferSteps] = useState(initState.showOfferSteps); // prettier-ignore
  const [showUpgrade, setShowUpgrade] = useState(initState.showUpgrade);
  const [showDowngrade, setShowDowngrade] = useState(initState.showDowngrade);
  const [disabled, setDisabled] = useState(initState.disabled);
  const [tabIndex, setTabIndex] = useState(initState.tabIndex);
  const [isOnPricingPage, setIsOnPricingPage] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const prevTabIndex = useRef(0);
  const router = useRouter();

  useReactiveVarListener(pricingModalDeepLink, (deepLink) => {
    if (deepLink === "offerSteps") {
      if (ctx.signedIn) {
        setShowOfferSteps(true);
        setTabIndex(tabIndex + 1);
      }
    }
    if (deepLink === "congrats") {
      setShowOfferSteps(true);
      setTabIndex(3);
    }
    /** When on the pricing page, an unauthenticated user clicking on the "Start Now" button will be shown the login,
     * rather than the free trial screen
     */
    if (deepLink === "auth") {
      setIsOnPricingPage(true);
      if (!ctx.signedIn) {
        setTabIndex(2);
      } else {
        setShowOfferSteps(true);
        setTabIndex(2);
      }
    }
  });

  // When a user logs in while on the pricing page, set the screen in the modal accordingly.
  useEffect(() => {
    if (ctx.signedIn && isOnPricingPage) {
      setShowOfferSteps(true);
      setTabIndex(2);
    }
  }, [ctx.signedIn, isOnPricingPage]);

  // Reset on close
  useEffect(() => {
    if (visible) {
      return;
    }

    // Detect sport mismatch (on awarded offer)
    const properSportCount = getTierSportCount(getProductTier(ctx.subscriptionLevel)); // prettier-ignore
    if (
      tabIndex === 3 && // thank you view
      showOfferSteps && // offer awarded
      properSportCount &&
      properSportCount !== ctx.subscriptionSports.length
    ) {
      sportCountMismatch(true);
    }

    // Reset state
    setState(initState);
    pricingModalDeepLink("");
  }, [visible]);

  useEffect(() => {
    if (visible) {
      AnalyticsService.track("Pricing Modal View", {
        screen: "PricingModal",
        tab: selectedTier.durationTitle,
        subscriptionLevel: `${selectedTier.title} ${selectedTier.durationTitle}`,
        step: ["Pricing", "Auth", "Checkout"][tabIndex] || "Thank You",
        upgradeSource: ctx.subscriptionUpgradeSource,
      });
    }
  }, [visible, tabIndex]);

  // Tab change
  useEffect(() => {
    // Skip sport/product selection for BetMGM/Fanduel offer
    const skipSportSelection = showOfferSteps; // prettier-ignore
    if (skipSportSelection && tabIndex === 1) {
      setTabIndex(tabIndex > prevTabIndex.current ? tabIndex + 1 : tabIndex - 1); // prettier-ignore
      return;
    }

    prevTabIndex.current = tabIndex;
  }, [tabIndex]);

  // Current sub logic (old)
  const isMobilePlan: boolean = ctx.subscriptionLevel ? Boolean(mobileSubscriptionLookup[ctx.subscriptionLevel]) : false; // prettier-ignore
  const subscriptionLevel: string = isMobilePlan && ctx.subscriptionLevel ? webSubscriptionLookup[ctx.subscriptionLevel] : ""; // prettier-ignore
  const userSubscription = userSubscriptions[0] || {};

  let currentTier, tierIndex = 1, productIndex = 0, isSubscribed = false; // prettier-ignore

  for (let key in tiers) {
    if (subscriptionLevel?.includes(key)) {
      tierIndex = tiers[key];
    }
  }

  for (let j = 0; j < ACTIVE_MOBILE_PRODUCTS[tierIndex].length; j++) {
    if (subscriptionLevel === ACTIVE_MOBILE_PRODUCTS[tierIndex][j].key) {
      productIndex = j;
      currentTier = ACTIVE_MOBILE_PRODUCTS[tierIndex][productIndex];
      isSubscribed = true;
      break;
    }
  }

  const currentTierValue = isSubscribed ? Math.pow(10, tierIndex) + productIndex : undefined; // prettier-ignore

  const setState = (options: any) => {
    options.sportList !== undefined && setSportList(options.sportList);
    options.selectedTier !== undefined && setSelectedTier(options.selectedTier);
    options.selectedPeriod !== undefined && setSelectedPeriod(options.selectedPeriod); // prettier-ignore
    options.upsellTier !== undefined && setUpsellTier(options.upsellTier); // prettier-ignore
    options.showCheckout !== undefined && setShowCheckout(options.showCheckout); // prettier-ignore
    options.showUpgrade !== undefined && setShowUpgrade(options.showUpgrade); // prettier-ignore
    options.showDowngrade !== undefined && setShowDowngrade(options.showDowngrade); // prettier-ignore
    options.showOfferSteps !== undefined && setShowOfferSteps(options.showOfferSteps); // prettier-ignore
    options.disabled !== undefined && setDisabled(options.disabled);
    options.tabIndex !== undefined && setTabIndex(options.tabIndex);
  };

  const onSelect = (options: ProductOptions) => {
    setState(options);
    setTabIndex(tabIndex + 1);
  };

  const toggleModal = () => ctx.togglePricingModal({});

  // Modals should not be in the DOM if not visible
  if (!visible) {
    return null;
  }

  return (
    <Modal
      className="rotoql-pricing-modal__dialog"
      modalClassName="rotoql-pricing-modal"
      wrapClassName="rotoql-pricing-modal__wrapper"
      backdropClassName="rotoql-pricing-modal__backdrop"
      isOpen={visible}
      slide="false"
      size="lg"
      centered
    >
      <React.Fragment>
        {tabIndex > 0 && tabIndex < 3 && (
          <div className="rotoql-pricing-modal__back" onClick={() => setTabIndex(tabIndex - 1)}>
            <Icon path={mdiChevronLeft} size="26px" color="#262626" />
            Back
          </div>
        )}
        <div className="rotoql-pricing-modal__close" onClick={() => !disabled && toggleModal()}>
          <Icon path={mdiClose} size="20px" color="#262626" />
        </div>
        {isMobilePlan ? (
          <MobileSubView toggleModal={toggleModal} />
        ) : (
          <React.Fragment>
            {tabIndex === 0 && <PeriodView toggleModal={toggleModal} onSelectPeriod={onSelect} />}
            {tabIndex === 1 && (
              <PricingView
                onGoBack={() => setTabIndex(tabIndex - 1)}
                onSelectProduct={onSelect}
                subscriptionLevel={subscriptionLevel}
                userSubscription={userSubscription}
                currentTierValue={currentTierValue}
                currentTier={currentTier}
                source={ctx.subscriptionUpgradeSource}
                duration={selectedPeriod}
                tierIndex={ctx.subscriptionDefaultTierIndex ?? undefined}
                sportList={ctx.subscriptionDefaultSports || undefined}
              />
            )}
            {!ctx.signedIn && tabIndex === 2 && <AuthView />}
            {ctx.signedIn && tabIndex === 2 && (
              <React.Fragment>
                {showOfferSteps ? <OfferStepsView tabIndex={tabIndex} setTabIndex={setTabIndex} /> : null}
                {showCheckout ? (
                  <CheckoutView
                    couponId={router?.query?.couponId as string | undefined}
                    sportList={sportList}
                    selectedTier={selectedTier}
                    upsellTier={upsellTier}
                    toggleModal={toggleModal}
                    slideNext={() => setTabIndex(tabIndex + 1)}
                    toggleDisabled={() => setDisabled(!disabled)}
                    setInvoice={(value) => setInvoiceId(value)}
                  />
                ) : null}
                {showUpgrade ? (
                  <UpgradeView
                    currentTier={currentTier}
                    selectedTier={selectedTier}
                    toggleModal={toggleModal}
                    userSubscription={userSubscription}
                    sportList={sportList}
                  />
                ) : null}
                {showDowngrade ? (
                  <DowngradeView
                    currentTier={currentTier}
                    selectedTier={selectedTier}
                    toggleModal={toggleModal}
                    userSubscription={userSubscription}
                    sportList={sportList}
                  />
                ) : null}
              </React.Fragment>
            )}
            {tabIndex === 3 && (
              <ThankYouView awarded={showOfferSteps} selectedTier={selectedTier} invoiceId={invoiceId} />
            )}
          </React.Fragment>
        )}
        <style jsx>{styles}</style>
      </React.Fragment>
    </Modal>
  );
};

export default PricingModal;
